import * as ActionTypes from '../constants/messages.constants'
import { USER_MESSAGES_CALL_TO_RECIPIENT } from '../constants/messages.constants'

const initialState = {
  messages: [],
  messagesNotify: [],
  call: null,
  callToRecipient: null,
  chatId: null,
  peer: null,
  loadings: {
    messages: false,
  },
  errors: {
    messages: null,
  },
}

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MESSAGE_NOTIFY: {
      return { ...state, messagesNotify: [...action.payload] }
    }
    case ActionTypes.USER_MESSAGES_INFO_REQUEST: {
      return {
        ...state,
      }
    }
    case ActionTypes.USER_MESSAGES_INFO_SUCCESS: {
      return {
        ...state,
        messages: [...action.payload].sort(
          (a, b) => new Date(b.time) - new Date(a.time),
        ),
        chatId: state.chatId
          ? {
              ...action.payload.find(
                (c) => c.conversation_id === state.chatId.conversation_id,
              ),
            }
          : null,
      }
    }
    case ActionTypes.CHAT_ID: {
      return {
        ...state,
        chatId: action.payload,
      }
    }
    case ActionTypes.USER_MESSAGES_CALL: {
      return {
        ...state,
        call: action.payload,
      }
    }
    case ActionTypes.USER_MESSAGES_CALL_TO_RECIPIENT: {
      return {
        ...state,
        callToRecipient: action.payload,
      }
    }
    // case ActionTypes.USER_MESSAGES_CALL: {
    //   const call = state.call.length > 0
    //   return {
    //     ...state,
    //     call: call ? [...state.call, action.payload] : [action.payload],
    //   }
    // }
    case ActionTypes.SEND_MESSAGES_REQUEST: {
      return {
        ...state,
        loadings: { messages: true },
      }
    }
    case ActionTypes.NEW_MESSAGES_SUCCESS: {
      const oldChatIndex = state.messages.findIndex(
        (m) => m.conversation_id === action.payload.conversation_id,
      )

      if (oldChatIndex !== -1) {
        const updatedChat = {
          ...state.messages[oldChatIndex],
          message: action.payload.message,
          image: action.payload.image,
          voice_note: action.payload.voice_note,
          shared: action.payload.shared,
          createdAt: action.payload.createdAt,
          messages: [
            ...state.messages[oldChatIndex].messages,
            action.payload.messages,
          ],
        }
        const messages = [
          ...state.messages.slice(0, oldChatIndex),
          updatedChat,
          ...state.messages.slice(oldChatIndex + 1),
        ]

        return {
          ...state,
          messages: messages.sort(
            (a, b) => new Date(b.time) - new Date(a.time),
          ),
          chatId: state.chatId ? updatedChat : null,
        }
      } else {
        const messages = [
          ...state.messages,
          { ...action.payload, messages: [action.payload.messages] },
        ]
        return {
          ...state,
          messages: messages.sort(
            (a, b) => new Date(b.time) - new Date(a.time),
          ),
          loadings: { messages: true },
          chatId: state.chatId
            ? {
                ...action.payload,
                messages: [...state.chatId.messages, action.payload.messages],
              }
            : null,
        }
      }
    }

    case ActionTypes.CALL_PEER: {
      return { ...state, peer: action.payload }
    }

    default:
      return state
  }
}

export default messagesReducer
