import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { getAvatarUrl, socketUrl } from '../../api'
import { IconActions, IconCall, IconCallVideo } from '../../assets'
import * as actionsMessages from '../../actions/messages.actions'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

function ChatHeader({
  chatId,
  translate,
  online,
  onChatId,
  siteInfo,
  userInfo,
  dispatch,
}) {
  const isOnline =
    online.length > 0 &&
    online.some((item) => item.user_id === chatId.user.user_id)
  const peer = useSelector((state) => state.messages.peer)
  const roomId = uuidv4()

  const caller = ({ video }) => {
    const msg = {
      user_id: userInfo.user_id,
      recipient_id: chatId.user.user_id,
      conversation_id: chatId.conversation_id,
      user: chatId.user,
      video,
    }
    dispatch(actionsMessages.callUserRecipient(msg))
  }

  const callUser = ({ video, roomId }) => {
    const msg = {
      user_id: userInfo.user_id,
      recipient_id: chatId.user.user_id,
      conversation_id: chatId.conversation_id,
      user: userInfo,
      video,
      roomId: roomId,
    }
    if (peer.open) msg.peerId = peer._id
    socketUrl.emit('callUser', msg)
  }

  const handleAudioCall = () => {
    caller({ video: false, roomId })
    callUser({ video: false, roomId })
  }

  const handleVideoCall = () => {
    caller({ video: true, roomId })
    callUser({ video: true, roomId })
  }

  return (
    <div className={styles.chatHeaderContainer}>
      <Link
        to={`/${chatId.user.user_name}`}
        className={styles.userLink}
        onClick={() => {
          onChatId(null)
        }}
      >
        <div className={styles.avatarContainer}>
          <img
            src={
              chatId.user.user_avatar_cover
                ? getAvatarUrl(chatId.user.user_avatar_cover)
                : chatId.user.user_picture
                  ? getAvatarUrl(chatId.user.user_picture)
                  : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
            }
            alt={chatId.user.user_lastname}
            className={styles.avatar}
          />

          <div
            className={styles.isOnline}
            style={{
              backgroundColor: isOnline ? 'rgb(34 197 94)' : 'rgb(138 143 140)',
            }}
          />
        </div>
        <div className={styles.userMessageInner}>
          <div className={styles.userName}>
            {chatId.user.user_firstname} {chatId.user.user_lastname}
          </div>
          <div
            className={styles.onlineText}
            style={{
              color: isOnline ? 'rgb(34 197 94)' : 'rgb(138 143 140)',
            }}
          >
            {isOnline ? translate('online') : translate('offline')}
          </div>
        </div>
      </Link>
      <div className={styles.containerButtons}>
        <button className={styles.button} onClick={handleAudioCall}>
          <IconCall width={30} height={30} />
        </button>
        <button className={styles.button} onClick={handleVideoCall}>
          <IconCallVideo width={30} height={30} />
        </button>
        <button className={styles.button}>
          <IconActions width={30} height={30} />
        </button>
      </div>
    </div>
  )
}

export default ChatHeader
