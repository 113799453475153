import { useDispatch, useSelector } from 'react-redux'
import audiobell from './assets/audio/got-it-done-613.mp3'
import audiobellM from './assets/audio/telegram_soundin.mp3'
import { useEffect, useRef } from 'react'
import * as actionStore from './actions/stores.actions'
import * as actionPosts from './actions/post.actions'
import * as actionApp from './actions/app.actions'
import * as actionMessages from './actions/messages.actions'
import CallModal from './components/CallModal'
import Peer from 'peerjs'

const SocketClient = ({ socket, id, user }) => {
  const dispatch = useDispatch()
  const audioRef = useRef()
  const audioRef2 = useRef()
  const call = useSelector((state) => state.messages.call)
  const callToRecipient = useSelector((state) => state.messages.callToRecipient)

  useEffect(() => {
    const newPeer = new Peer(undefined, {
      path: '/socket',
      secure: true,
      host: 'socket-hat.viachelav-dev.ru',
    })

    dispatch(actionMessages.peer(newPeer))
  }, [dispatch])

  useEffect(() => {
    dispatch(actionApp.joinUserInfo(user))
  }, [dispatch, user])

  useEffect(() => {
    dispatch(actionMessages.messagesUser())
  }, [dispatch])

  useEffect(() => {
    socket.on('checkUserOnlineToMe', (data) => {
      dispatch(actionApp.joinUserInfoSuccess(data))
    })
    return () => {
      socket.off(`checkUserOnlineToMe`)
    }
  }, [dispatch, socket])

  useEffect(() => {
    if (id) {
      socket.on(`update_itemsStore${id}`, (data) => {
        dispatch(actionStore.updateItemsStores(data))
      })
      return () => {
        socket.off(`update_itemsStore${id}`)
      }
    }
  }, [dispatch, socket, id])

  useEffect(() => {
    socket.on(`update_posts`, (data) => {
      dispatch(actionPosts.updatePost(data))
    })
    return () => {
      socket.off(`update_posts`)
    }
  }, [dispatch, socket])

  useEffect(() => {
    socket.on(`new_posts`, (data) => {
      dispatch(actionPosts.newPost(data))
    })
    return () => {
      socket.off(`new_posts`)
    }
  }, [dispatch, socket])

  useEffect(() => {
    if (id) {
      socket.on(`allMessagesUsers${id}`, (data) => {
        dispatch(actionMessages.messagesUserSuccess(data))
      })
      return () => {
        socket.off(`allMessagesUsers${id}`)
      }
    }
  }, [dispatch, socket, id])

  useEffect(() => {
    if (id) {
      socket.on(`newMessageUser${id}`, (data) => {
        dispatch(actionMessages.newMessagesSuccess(data))
        if (audioRef.current || audioRef2.current) {
          if (data.recipient_id === id) {
            audioRef.current.play()
          } else {
            audioRef2.current.play()
          }
        }
      })
      return () => {
        socket.off(`newMessageUser${id}`)
      }
    }
  }, [dispatch, socket, id, audioRef2.current, audioRef.current])

  useEffect(() => {
    socket.on('messagesNotify', (data) => {
      if (data || data.length > 0) {
        const notify = data.find((u) => u.recipient_id === id)
        if (notify && audioRef.current) {
          audioRef.current.play()
        }
      }
      dispatch(actionMessages.messageNotify(data))
    })
    return () => {
      socket.off(`messagesNotify`)
    }
  }, [socket, dispatch, id])

  useEffect(() => {
    socket.on(`callUserToClient`, (data) => {
      dispatch(actionMessages.callUser(data))
    })

    return () => socket.off(`callUserToClient`)
  }, [socket, dispatch])

  const calls = callToRecipient || call

  return (
    <>
      <audio controls ref={audioRef} style={{ display: 'none' }}>
        <source src={audiobell} type="audio/mp3" />
      </audio>
      <audio controls ref={audioRef2} style={{ display: 'none' }}>
        <source src={audiobellM} type="audio/mp3" />
      </audio>
      {calls && <CallModal socket={socket} user={user} call={calls} />}
    </>
  )
}
export default SocketClient
