import * as ActionTypes from '../constants/messages.constants'
export const messagesUser = (values) => ({
  type: ActionTypes.USER_MESSAGES_INFO_REQUEST,
  payload: values,
})

export const messagesUserSuccess = (values) => ({
  type: ActionTypes.USER_MESSAGES_INFO_SUCCESS,
  payload: values,
})

export const sendMessage = (values) => ({
  type: ActionTypes.SEND_MESSAGES_REQUEST,
  payload: values,
})

export const newMessagesSuccess = (values) => ({
  type: ActionTypes.NEW_MESSAGES_SUCCESS,
  payload: values,
})

export const messageView = (values) => ({
  type: ActionTypes.MESSAGE_VIEW_REQUEST,
  payload: values,
})

export const messageNotify = (values) => ({
  type: ActionTypes.MESSAGE_NOTIFY,
  payload: values,
})

export const callUser = (values) => ({
  type: ActionTypes.USER_MESSAGES_CALL,
  payload: values,
})

export const callUserRecipient = (values) => ({
  type: ActionTypes.USER_MESSAGES_CALL_TO_RECIPIENT,
  payload: values,
})

export const peer = (values) => ({
  type: ActionTypes.CALL_PEER,
  payload: values,
})

export const chatId = (values) => ({
  type: ActionTypes.CHAT_ID,
  payload: values,
})
